function resizeLogic() {
  const heightHeader = document.querySelector("header").offsetHeight
  document.querySelector(".header-fix").style.height = heightHeader + "px";

  if(window.innerWidth < 768 && document.querySelector('.usp-carousel')) {
    jQuery('.usp-carousel').slick({
      arrows: false,
      autoplay: true,
      dots: false,
      infinite: true,
      mobileFirst: true,
      slidesToScroll: 1,
      slidesToShow: 1,
    });
  }
}
let toggleMenu = () => {
  document.querySelector("#burger").classList.toggle('open');
  document.querySelector("#navigation-mobile > ul").classList.toggle('open');
};

document.addEventListener("DOMContentLoaded", function () { // Ready
  window.addEventListener("resize", resizeLogic);

  // Header Fix
  resizeLogic();

  // Burger click
  if(document.querySelector("#burger")) {
    document.querySelector("#burger").addEventListener("click", function () {
      toggleMenu();
    });
  }

  // Live Chat/Menu
  if(document.querySelector(".menu_livechat")) {
    document.querySelector(".menu_livechat").addEventListener("click", function () {
      document.querySelector("#chat-widget-container").classList.toggle("active");
      document.querySelector("#burger").click();
    });
  }

  if(document.querySelectorAll(".gallery-image")) {
    document.querySelectorAll(".gallery-image").forEach((el) => {
      el.addEventListener("click", function (e) {
        let ref = e.target.closest('.gallery-image').dataset.ref;
        document.querySelector(".gallery-image.active").classList.remove("active");
        e.target.closest('.gallery-image').classList.add("active");
        document.querySelector("#main-image img").src = ref;
      });
    });
  }

  // Mobile Menu Submenus
  if(document.querySelectorAll(".submenu-toggle")) {
    document.querySelectorAll(".submenu-toggle").forEach(function (el) {
      el.addEventListener("click", function () {
        el.classList.toggle("open");
        el.previousElementSibling.classList.toggle("open");
        el.nextElementSibling.classList.toggle("open");
      });
    });
  }

  // Menu Back Buttons
  if(document.querySelectorAll(".back-btn")) {
    document.querySelectorAll(".back-btn").forEach(function (el) {
      el.addEventListener("click", function () {
        document.querySelectorAll(".mega-menu.open").forEach(function (el) {
          el.classList.remove("open");
        });

        document.querySelectorAll(".submenu-toggle.open").forEach(function (el) {
          el.classList.remove("open");
        });

        document.querySelectorAll(".nav-link.open").forEach(function (el) {
          el.classList.remove("open");
        });
      });
    });
  }

  // Filter button spinner
  if(document.querySelectorAll(".filter-btn")) {
    document.querySelectorAll(".filter-btn").forEach(function (el) {
      el.addEventListener("click", function () {
        el.querySelector('svg').classList.add("button-spinner");
        el.classList.add("spinner");
      });
    });
  }

  // mobile filter toggle button
  if(document.querySelectorAll("button.filter-toggle")) {
    document.querySelectorAll("button.filter-toggle").forEach(function (el) {
      el.addEventListener("click", function (e) {
        e.preventDefault();

        el.classList.toggle("open");
        let parent = el.closest("#mobile-filter-controls");
        let slider = parent.previousElementSibling;
        parent.classList.toggle("open");
        slider.classList.toggle("open");
      });
    });
  }

  // Trigger Filtering on sort option change
  if(document.querySelector("#sort-ref")) {
    document.querySelector("#sort-ref").addEventListener("change", function () {
      document.querySelector(".sort-filter-hidden").value = document.querySelector("#sort-ref").value;
      document.querySelector(".filter-btn").classList.add("spinner");
      document.querySelector(".filter-form").submit();
    })
  }

  // Trigger Filtering on sort option change mobile
  if(document.querySelector("#custom-sort-ref, #mobile-custom-sort-ref")) {
    document.querySelector("#custom-sort-ref, #mobile-custom-sort-ref").addEventListener("change", function (el) {
      document.querySelector(".sort-filter-hidden").value = el.target.options[el.target.selectedIndex].value;
      document.querySelector(".filter-btn").classList.add("spinner");
      document.querySelector(".filter-form").submit();
    })
  }

  // Show register account
  if(document.querySelector(".create-account-btn")) {
    document.querySelector(".create-account-btn").addEventListener("click", function (e) {
      e.preventDefault();
      document.querySelector("#customer_login .col-1").classList.add("hidden");
      document.querySelector("#customer_login .col-2").classList.add("show");
    })
  }

  // Show signin account
  if(document.querySelector(".signin-btn")) {
    document.querySelector(".signin-btn").addEventListener("click", function (e) {
      e.preventDefault();
      document.querySelector("#customer_login .col-1").classList.remove("hidden");
      document.querySelector("#customer_login .col-2").classList.remove("show");
    })
  }

  // Custom arrows
  if(document.querySelector(".custom-next")) {
    document.querySelector(".custom-next").addEventListener("click", function () {
      let currentActive = document.querySelector("img.flex-active");
      let currentLi = currentActive.closest("li");
      let nextLi = currentLi.nextElementSibling;
      if (nextLi) {
        nextLi.querySelector("img").click();
      }
    })
  }

  if(document.querySelector(".custom-prev")) {
    document.querySelector(".custom-prev").addEventListener("click", function () {
      let currentActive = document.querySelector("img.flex-active");
      let currentLi = currentActive.closest("li");
      let nextLi = currentLi.previousElementSibling;
      if (nextLi) {
        nextLi.querySelector("img").click();
      }
    })
  }

  if(document.querySelector("form.cart")) {
    document.querySelector("form.cart").addEventListener("click", function (e) {
      // Get current quantity values
      let qty = document.querySelector("form.cart .qty");
      let val = parseFloat(qty.value);
      let max = parseFloat(qty.getAttribute("max"));
      let min = parseFloat(qty.getAttribute("min"));
      let step = parseFloat(qty.getAttribute("step"));

      // Change the value if plus or minus
      if (e.target.classList.contains("plus")) {
        if (max && max <= val) {
          qty.value = max;
        } else {
          qty.value = val + step;
        }
      } else {
        if (e.target.classList.contains("minus")) {
          if (min && min >= val) {
            qty.value = min;
          } else if (val > 1) {
            qty.value = val - step;
          }
        }
      }
    })
  }

  if(document.querySelector("#further-desc button.read-more")) {
    document.querySelectorAll("#further-desc button.read-more").forEach(function (el) {
      el.addEventListener("click", function (e) {
        e.preventDefault();
        let full = el.parentNode.querySelector("span.full");
        let preview = el.parentNode.querySelector("span.preview");

        full.classList.toggle("d-none");
        preview.classList.toggle("d-none");

        this.classList.toggle("expanded");
        if (this.classList.contains("expanded")) {
          this.innerHTML = "Read less <i class='fa-solid fa-ellipsis'></i>";
        } else {
          this.innerHTML = "Read more <i class='fa-solid fa-ellipsis'></i>";
        }
      })
    })
  }

  if(document.querySelector("#cat-description button.read-more")) {
    document.querySelectorAll("#cat-description button.read-more").forEach(function (el) {
      el.addEventListener("click", function (e) {
        e.preventDefault();
        let full = el.parentNode.querySelector("span.full");
        let preview = el.parentNode.querySelector("span.preview");

        full.classList.toggle("d-none");
        preview.classList.toggle("d-none");

        this.classList.toggle("expanded");
        if (this.classList.contains("expanded")) {
          this.innerHTML = "Read less <i class='fa-solid fa-ellipsis'></i>";
        } else {
          this.innerHTML = "Read more <i class='fa-solid fa-ellipsis'></i>";
        }
      })
    })
  }

  if(document.querySelector("#mobile-product-info button.read-more")) {
    document.querySelectorAll("#mobile-product-info button.read-more").forEach(function (el) {
      el.addEventListener("click", function (e) {
        e.preventDefault();
        let full = el.parentNode.querySelector("div.full");
        let preview = el.parentNode.querySelector("div.preview");

        full.classList.toggle("d-none");
        preview.classList.toggle("d-none");

        this.classList.toggle("expanded");
        if (this.classList.contains("expanded")) {
          this.innerHTML = "Read less <i class='fa-solid fa-ellipsis'></i>";
        } else {
          this.innerHTML = "Read more <i class='fa-solid fa-ellipsis'></i>";
        }
      })
    })
  }

  // Measurements check
  if(!document.querySelector('#Measurements-ref') && document.querySelector('#btn-measurements')) {
    document.querySelector('#btn-measurements').style.display = 'none';
  }

  // ==============================
  // MULTIPLE SUPPLIER FUNCTIONS
  // ==============================

  // Trigger the update on attribute selection change
  if(document.querySelector(".variation-input.plus")) {
    document.querySelectorAll(".variation-input.plus").forEach(function (el) {
      el.addEventListener("click", function () {
        quantityPlusMinus("plus", el.getAttribute("data-ref"));
        updateAddToBasket();
      });
    });
  }

  if(document.querySelector(".variation-input.minus")) {
    document.querySelectorAll(".variation-input.minus").forEach(function (el) {
      el.addEventListener("click", function () {
        quantityPlusMinus("minus", el.getAttribute("data-ref"));
        updateAddToBasket();
      });
    });
  }

  // Update the basket price on input change
  if(document.querySelector(".quantity-input")) {
    document.querySelectorAll(".quantity-input").forEach(function (el) {
      el.addEventListener("input", function () {
        updateAddToBasket();
      });
    });
  }

  if (document.querySelectorAll(".carousel-category_gallery").length > 0) {
    document.querySelectorAll(".carousel-category_gallery").forEach(function (el) {

    })

  jQuery('.carousel-category_gallery').slick({
      arrows: true,
      nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24px" height="16px"><path fill="#FFFFFF" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>Next</button>',
      prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24px" height="16px"><path fill="#FFFFFF" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>Previous</button>',
      dots: false,
      infinite: false,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
          }
        },
      ],
      slidesToScroll: 1,
      slidesToShow: 2,
    });
  }

  if(document.querySelector('.accordion-button')) {
    console.log('yup')
    document.querySelectorAll('.accordion-button').forEach(function (el) {
      el.addEventListener('click', function () {
        el.classList.toggle('hide-text');
        el.parentNode.parentNode.querySelector('.accordion-collapse').classList.toggle('show');
        el.parentNode.querySelector('.accordion-button:before').style.transform = 'rotate(180deg)'
      });
    });
  }
});

/**
 * Add or remove 1 of the target variation to the basket
 *
 * @param type keyword indicates which action to perform -- either 'plus' or 'minus'
 * @param ref the reference ID of the variation to target
 */
function quantityPlusMinus(type, ref) {
  const target = document.querySelector('.quantity-input#' + ref);
  let current = parseInt(target.value);

  if (type === "plus") {
    document.querySelector("input#" + ref).value = current + 1;
  } else {
    if (current !== 0) {
      document.querySelector("input#" + ref).value = current - 1;
    }
  }
  const e = new Event("change");
  target.dispatchEvent(e);
}

/**
 * Updates the basket price based on the quantity of models selected using the +/- buttons
 */
function updateAddToBasket() {
  let array = [];

    document.querySelectorAll("input.quantity-input").forEach(function (el) {
      let price = el.getAttribute("data-price");
      let value = el.value;
      let new_price = price * value;
      if (new_price > 0) {
        array.push({
          price: new_price,
        });
      }
    })

  if (array.length === 0) {
    document.querySelector('#var-total .text').textContent = "XX.xx";
    document.querySelector('#btn-var_add').classList.add("disabled");
  } else {
    let total = 0;

    array.forEach(function (element) {
      total += element.price;
    })

    total = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    document.querySelector('#var-total .text').textContent = total;
    document.querySelector("#btn-var_add").classList.remove("disabled");
  }
}